<template>
 <preview-check-box
    :photo="photo"
    :values="values"
 />
</template>
<script>
export default {
  components: {
    PreviewCheckBox:()=> import('@table/cell/PreviewCheckBox')
  },
  props: {
    photo: {
      type: Object,
      default: () => {}
    },
    values: {
      type: Object,
      default: () => {}
    }
  },
}
</script>
